import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "【2018/12/7 開催予告】Classi / メドレー 合同で AWS 勉強会を開催します!",
  "date": "2018-12-04T08:59:13.000Z",
  "slug": "entry/2018/12/04/175913",
  "tags": ["medley"],
  "hero": "./2018_12_04.png",
  "heroAlt": "勉強会予告"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181204/20181204165947.jpg",
      "alt": "20181204165947.jpg"
    }}></img>
    <p>{`みなさん、こんにちは。開発本部エンジニアの平木です。`}</p>
    <p>{`さて、いよいよ 12 月に突入して世間の雰囲気も師走に相応わしいムードになっている中、みなさんいかがお過しでしょうか?`}</p>
    <p>{`来たる 2018/12/7(金)にメドレーと教育現場支援の ICT プラットフォームを展開している Classi さんの合同で AWS 勉強会を開催します。`}</p>
    <p><a parentName="p" {...{
        "href": "https://realtech-night.connpass.com/event/110083/"
      }}>{`https://realtech-night.connpass.com/event/110083/`}</a></p>
    <p>{`そこで今回は主にメドレーの発表はどのようなものがあるかをちらっと、ご紹介しながらイベントの告知をしようと思います。`}</p>
    <h1>{`なぜ、Classi さんとの合同イベントをするのか`}</h1>
    <p>{`弊社メドレーは医療・ヘルスケア分野の課題解決するサービスを展開しており現在は 5 つのサービスを運営しています。全てのサービスで AWS を活用しており、その恩恵をフルに活かして日々運用をしています。Classi さんも全国の教育機関で使われるサービスのインフラを AWS で構築されています。`}</p>
    <p>{`医療業界のメドレー、教育業界の Classi さんと業界は全然違うのですが、国の施策や法律に密着したサービス運営をしている点や、社会のインフラ的な事業にチャレンジしている点、もちろん先ほど紹介したように AWS をフル活用している点などの共通項がありました。`}</p>
    <p>{`こうしたことから、それぞれの業界ならではの話も折り混ぜた AWS の活用・運用・構築などの事例を合同で発表すると面白いのではないか?ということで実現しました。`}</p>
    <h1>{`メドレーの発表を少し頭出し`}</h1>
    <p>{`ということで実現したこの勉強会ですが、メドレーのセッションのタイトルと内容を少しだけご紹介していきたいと思います。`}</p>
    <h2>{`ガイドライン対応のための AWS セキュリティ系マネージドサービスの活用`}</h2>
    <p>{`そもそもガイドラインとは何ぞや?という話になってきますが、"`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/karte"
      }}>{`CLINICS カルテ`}</a>{`"を構築する上で避けては通れなかった 3 省 4 ガイドラインという、関係省庁から出されていた医療情報取り扱いにおけるガイドラインです。(現在は 3 省 3 ガイドラインになっていますが…)`}</p>
    <p>{`このガイドラインに準拠するためにどのように AWS の各サービスを使って CLINICS カルテのインフラを構築しているか?という発表を中畑からします。`}</p>
    <h2>{`クライアント認証対応のための AWS 構成の変遷`}</h2>
    <p>{`田中からは、こちらもガイドラインで対応必須とされている"クライアント認証"を CLINICS カルテではどのように実現しているか?というお話をします。`}</p>
    <p>{`AWS 上に構築された CLINICS カルテで、紆余曲折ありながらクライアント認証を実現させるための苦労話を発表していきます。`}</p>
    <h2>{`クラウドと院内システムをつなぐための AWS IoT の活用`}</h2>
    <p>{`CLINICS カルテを始めとした電子カルテでは、医療機関内の院内機器など外部の機器やサービスと連携する必要があります。`}</p>
    <p>{`こうした外部機器やサービスと、CLINICS カルテをどのように連携させていくのか?また可用性を担保しつつ、AWS をどう活用して実現していくのかというセッションを有馬からお話します。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`このように、色々な制約などがあるなかで、AWS をどのように活用しているのかというのが違った側面から分かるようなセッション内容になっていると思います。`}</p>
    <p>{`また、発表者の 1 人 Classi の本間さんは先日の`}<a parentName="p" {...{
        "href": "https://reinvent.awsevents.com/"
      }}>{`re:Invnent 2018`}</a>{`に参加されてきたとのことで、現地のお土産話などが懇親会で聞けると思います!`}</p>
    <p>{`年の瀬も差しせまってきた忙しい中だとは思いますが、みなさま参加してみてはいかがでしょうか?`}</p>
    <p>{`ご参加、お待ちしています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://realtech-night.connpass.com/event/110083/"
      }}>{`https://realtech-night.connpass.com/event/110083/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      